import React from 'react';
import {Card, CardTitle, CardText, CardActions, Button} from "react-mdl";
import projects from '../data/projects';


const Projects = () => {

    return (
        <div className="container-fluid main-color pt-4" >

            <h1 className="row text-white text-uppercase d-flex justify-content-center mb-4">My projects</h1>

            <div className="row pt-3 w-75 mx-auto">
                {projects.map(project => (
<Card shadow={0} className="bg-dark col-md-5 col-10 my-3 mx-3" style={{height: '23rem'}} key={project.id}>
    <CardTitle expand className="row" style={{
        color: `${project.titleColor}`,
        background: `url(${project.img}) center / cover`
    }}>{project.title}</CardTitle>
    <CardText className="text-secondary row">
        {project.description}
    </CardText>
    <CardActions border className="d-flex justify-content-around">
        <a href={project.github} target="_blank" rel="noreferrer">
            <Button className="text-white text-center">Github</Button></a>
        <a href={project.demo} target="_blank" rel="noreferrer">
            <Button className="text-white text-center">Live Demo</Button></a>
    </CardActions>
</Card>
                ))
                }

            </div>
        </div>
    );
};

export default Projects;