import React from 'react';

const EducationExperience = () => {
    return (
        <div className="container-fluid second-color py-4 size">
        <div className="row education-experience w-75 text-center mx-auto">
            <div className="col-lg-6 col-10">
                <h2 className="mb-2">Education</h2>
                <hr/>
                <p className="font-weight-bold mb-0 mt-1">2020 - 2021</p>
                <p>Kaunas Information Technology School</p>
                <p className="font-weight-bold mb-0">2010 - 2014</p>
                <p>Vytautas Magnus University (VMU)</p>
            </div>
            <div className="col-lg col-10">
                <h2 className="mb-2">Experience</h2>
                <hr/>
                <p className="font-weight-bold mb-0 mt-1">2021 02 - 2021 03</p>
                <p className="mb-0">Kaunas Information Technology School</p>
                <p className="text-secondary">Web developer intern</p>
                <p className="font-weight-bold mb-0 mt-1">2014 - 2020</p>
                <p className="mb-0">UAB Baltic RED</p>
                <p className="text-secondary">Accountant</p>
                <p className="font-weight-bold mb-0">2013 - 2014</p>
                <p className="mb-0">Audito UAB "In salvo"</p>
                <p className="text-secondary">Accountant</p>
            </div>
        </div>
    </div>
    );
};

export default EducationExperience;
