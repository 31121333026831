import React from 'react';
import {Grid, Cell} from "react-mdl";

const Home = () => {

    return (
        <div style={{width: "100%", margin: "auto", height: "100%"}} className="main-color">
            <Grid className="main-color ">
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <Cell col={12} className="home-cell">
                    <div className="d-flex justify-content-center mb-3">
                        <h1 className="shaking-name" style={{color: "#d1d7e3"}}>Hi, I'm Rimantė!</h1>
                    </div>
                    <div className="intro-text">
                        <h1 className="font-weight-bold text-white text-center mb-2 pt-2">Junior Web Developer</h1>
                        <hr/>
                        <p className="text-secondary p-2 text-center">HTML | CSS/SASS | Javascript | Bootstrap |
                            React | PHP | Laravel | MySQL </p>
                        <div className="social-icons d-flex justify-content-between mx-auto w-25 pb-2">
                            <a href="https://www.linkedin.com/in/rimant%C4%97-pomeckyt%C4%97-60b76899/" target="_blank" rel="noreferrer" className="mr-2">
                                <i className="fa fa-linkedin-square fa-2x text-white" aria-hidden="true"/>
                            </a>
                            <a href="https://github.com/rimantepomeckyte" target="_blank" rel="noreferrer" className="mr-2">
                                <i className="fa fa-github-square fa-2x text-white" aria-hidden="true"/>
                            </a>
                            <a href="mailto:rimante.pomeckyte@gmail.com" target="_blank" rel="noreferrer">
                                <i className="fa fa-google fa-2x text-white" aria-hidden="true"/>
                            </a>
                        </div>
                    </div>
                </Cell>
            </Grid>
        </div>
    );
};

export default Home;