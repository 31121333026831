import React from 'react';
import {ProgressBar} from "react-mdl";

const Skills = (props) => {
    return (
            <div className="row d-flex justify-content-center align-items-center">
                <p className="col-lg-1 col-3 mb-0 text-white" style={{textAlign: "center"}}>{props.skill}</p>
                <ProgressBar className="col-lg-6 col-8 border border-secondary" progress={props.progressBuffer} buffer={props.progressBuffer}/>
            </div>
    );
};

export default Skills;