const skills = [

    {
        name: "HTML5",
        progress: 93
    },
    {
        name: "CSS3",
        progress: 82
    },
    {
        name: "JavaScript",
        progress: 87
    },
    {
        name: "React",
        progress: 87
    },
    {
        name: "PHP",
        progress: 73
    },
    {
        name: "Laravel",
        progress: 73
    },
    {
        name: "MySQL",
        progress: 79
    },
    {
        name: "WordPress",
        progress: 55
    },
]

export default skills;
