import React from 'react';

const Contacts = () => {
    return (
        <div className="main-color container-fluid py-5 contacts">
                    <h2 className="text-white mb-1 row d-flex justify-content-center mb-1"> Contact Me</h2>
                    <hr/>
                    <div className="row d-flex justify-content-center mt-4">
                        <i className="fa fa-phone-square fa-3x text-white col-lg-1 col-2" aria-hidden="true" />
                         <a href="tel:+37063844141" style={{textDecoration: "none", fontSize: '20px'}} className="text-white font-weight-normal mb-0 col-lg-3 col-9">+370 638 44141</a>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                        <i className="fa fa-envelope-square fa-3x text-white col-lg-1 col-2" aria-hidden="true" />
                        <a className="mb-0 text-white col-lg-3 col-9 font-weight-normal" href="mailto:rimante.pomeckyte@gmail.com" style={{textDecoration: "none", fontSize: '20px'}}>rimante.pomeckyte@gmail.com</a>
                    </div>

        </div>
    );
};

export default Contacts;