import React from 'react';
import Bio from "./Bio";
import EducationExperience from "./EducationExperience";
import Skills from "./Skills";
import Contacts from "./Contacts";
import skills from "../data/skills";

const AboutMe = () => {
    return (
        <div className="about-me">
            <Bio/>
            <div className="container-fluid main-color py-5 size">
                {skills.map(skill => (
                    <Skills skill={skill.name} progressBuffer={skill.progress}/>
                ))}
            </div>
            <EducationExperience/>
            <Contacts/>
        </div>
    );
};

export default AboutMe;