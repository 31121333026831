import React from 'react';
import profile from '../img/profile-img.jpg';
import resume from '../img/Rimante_Pomeckyte_resume.pdf'

const Bio = () => {
    return (
        <div className="row p-5 second-color size">
            <div className="d-flex justify-content-center align-items-center profile-wrapper">
                <img src={profile} alt="profile" className="profile-img"/>
            </div>
            <div className="col-lg col-12">
                <h4 style={{fontSize: '28px'}} className="mt-4">Bio</h4>
                <p style={{fontSize: '16px'}}>
                    Hello, my name is Rimantė and I am a beginner web developer. I have recently finished web development course
                    at Kaunas
                    Information Technology School. During the studies I
                    found myself strong
                    and most interested in JavaScript, React, PHP, Laravel, also I have practical basics in Typescript, MySQL, JAVA
                    and experience with Git, Docker. I don't have a work
                    experience as a web developer, but I have completed a few projects for studies, I am a quick learner
                    and love
                    doing research and solving problems. I have a bachelor degree in Economics and Finance as well as
                    few years of
                    accounting experience. I would love to change my career as I find web developing more challenging
                    and I just
                    love coding and making things actually happen, so my main goal is to become a professional web
                    developer.
                    I am self motivated so I can easily work on a project on my own, but I am a good team player as
                    well,
                    responsible and willing to give results.
                    <br/>
                    <a href={resume} download style={{textDecoration: 'none'}} className='text-dark'>Full resume here.</a>
                </p>
            </div>

        </div>
    );
};

export default Bio;
