import masters from "../img/masters-reviews.PNG";
import bookShop from "../img/book-shop.PNG";
import movies from "../img/movies.PNG";
import countries from "../img/countries.PNG";
import weather from "../img/weather.PNG";
import quiz from "../img/quiz.PNG"

const projects = [
    {
        id: 1,
        title: "Masters reviews",
        titleColor: '#000000',
        description: "PHP | Laravel | Bootstrap | SCSS | MySQL",
        img: [masters],
        github: "https://github.com/rimantepomeckyte/Masters_posts",
        demo: "http://masters-reviews.herokuapp.com/"
    },
    {
        id: 2,
        title: "Front-end quiz app",
        titleColor: '#000000',
        description: "HTML5 | CSS3 | ReactJS | Bootstrap ",
        img: [quiz],
        github: "https://github.com/rimantepomeckyte/front-end-quiz",
        demo: "https://front-end-quiz-one.vercel.app/"
    },
    {
        id: 3,
        title: "Book Shop app",
        titleColor: '#000000',
        description: "HTML5 | CSS3 | ReactJS | Bootstrap",
        img: [bookShop],
        github: "https://github.com/rimantepomeckyte/Book-shop-React",
        demo: "https://book-shop-react.vercel.app/"
    },
    {
        id: 4,
        title: "Weather App",
        titleColor: '#000000',
        description: "HTML5 | CSS3 | Javascript | Bootstrap | API",
        img: [weather],
        github: "https://github.com/rimantepomeckyte/Oru_prognoze",
        demo: "https://rimantepomeckyte.github.io/Oru_prognoze/"
    },
    {
        id: 5,
        title: "Movies app",
        titleColor: '#ffffff',
        description: "HTML5 | CSS3 | ReactJS | Bootstrap | API",
        img: [movies],
        github: "https://github.com/rimantepomeckyte/React_Filmai_2var",
        demo: "https://filmai-react2.vercel.app/"
    },
    {
        id: 6,
        title: "Countries app",
        titleColor: '#ffffff',
        description: "HTML5 | CSS3 | Javascript | Bootstrap | API",
        img: [countries],
        github: "https://github.com/rimantepomeckyte/Countries_app_with_React",
        demo: "https://countries-app-xi.vercel.app/"
    }
]

export default projects;