import React from 'react';
import Home from "./Home";
import Projects from "./Projects";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Content, Drawer, Header, Layout, Navigation} from "react-mdl";

import AboutMe from "./AboutMe";

const Main = () => {
    return (
        <Router>
            <div className="demo-big-content">
                <Layout>
                    <Header className="main-color" title=" " scroll>
                        <Navigation>
                            <Link className="link text-uppercase" style={{textDecoration: 'none'}} to="/">Home</Link>
                            <Link className="link text-uppercase" style={{textDecoration: 'none'}}  to="/projects">Projects</Link>
                            <Link className="link text-uppercase" style={{textDecoration: 'none'}}  to="/about">About Me</Link>
                        </Navigation>
                    </Header>
                    <Drawer title="Meniu">
                        <Navigation>
                            <Link to="/">Home</Link>
                            <Link to="/projects">Projects</Link>
                            <Link to="/about">About Me</Link>
                        </Navigation>
                    </Drawer>
                    <Content>
                        <div className="page-content"/>
                        <Switch>
                            <Route exact path="/">
                                <Home/>
                            </Route>
                            <Route path="/projects">
                                <Projects/>
                            </Route>
                            <Route path="/about">
                                <AboutMe/>
                            </Route>
                        </Switch>
                    </Content>

                </Layout>
            </div>
        </Router>
    );
};

export default Main;